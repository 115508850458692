const HeaderContainer = () => import('./HeaderContainer');
const MiniHeaderContainer = () => import('./MiniHeaderContainer');
const FooterContainer = () => import('./FooterContainer');
const HeroContainer = () => import('./HeroContainer');
const BannerContainer = () => import('./BannerContainer');
const CarouselContainer = () => import('./CarouselContainer');
const CarouselContainerAntracita = () => import('./CarouselContainerAntracita');
const GridBannersContainer = () => import('./GridBannersContainer');
const CompareProductsContainer = () => import('./CompareProductsContainer');
const ProductListingContainer = () => import('./ProductListingContainer');
const VerticalContainer = () => import('./VerticalContainer');
const ProjectsListContainer = () => import('./ProjectsListContainer');
const ProjectDetailsContainer = () => import('./ProjectDetailsContainer');

export {HeaderContainer as _HeaderContainer};
export {MiniHeaderContainer as _MiniHeaderContainer};
export {FooterContainer as _FooterContainer};
export {HeroContainer as _HeroContainer};
export {BannerContainer as _BannerContainer};
export {CarouselContainer as _CarouselContainer};
export {CarouselContainerAntracita as _CarouselContainerAntracita};
export {GridBannersContainer as _GridBannersContainer};
export {CompareProductsContainer as _CompareProductsContainer};
export {ProductListingContainer as _ProductListingContainer};
export {VerticalContainer as _VerticalContainer};
export {ProjectsListContainer as _ProjectsListContainer};
export {ProjectDetailsContainer as _ProjectDetailsContainer};
