const ArticleHeader = () => import('./ArticleHeader');
const ArticleAntracita = () => import('./ArticleAntracita');
const ArticleBosch = () => import('./ArticleBosch');
const ArticleEco = () => import('./ArticleEco');
const ArticlesWebContent = () => import('./ArticlesWebContent');
const ArticleCollapseWebContent = () => import('./ArticleCollapseWebContent');
const ArticleMinvu = () => import('./ArticleMinvu');
const ArticleEscuelasDeportivas = () => import('./ArticleEscuelasDeportivas');
const ArticleEscuelasDeportivasNota1 = () => import('./ArticleEscuelasDeportivasNota1');
const ArticleConstruyamosTuIdea = () => import('./ArticleConstruyamosTuIdea');

export * from './ArticleOurShops';
export * from './ArticlePaymentMethods';
export * from './ArticlePrivacidad';
export * from './ArticleReturnsAndExchanges';
export * from './ArticleServices';
export * from './ArticleTerminosYCondiciones';
export * from './ArticleTuOpnion';
export * from './ArticleWithdrawalConditions';
export * from './WhoAreWe';
export {ArticleHeader as _ArticleHeader};
export {ArticleAntracita as _ArticleAntracita};
export {ArticleBosch as _ArticleBosch};
export {ArticleEco as _ArticleEco};
export {ArticlesWebContent as _ArticlesWebContent};
export {ArticleCollapseWebContent as _ArticleCollapseWebContent};
export {ArticleMinvu as _ArticleMinvu};
export {ArticleEscuelasDeportivas as _ArticleEscuelasDeportivas};
export {ArticleEscuelasDeportivasNota1 as _ArticleEscuelasDeportivasNota1};
export {ArticleConstruyamosTuIdea as _ArticleConstruyamosTuIdea};
