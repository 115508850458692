export const setSpinnerVisibility = () => import('./page');
export const setMessageVisibility = () => import('./message');
export const emailcheck = () => import('./newsletter');
export const acceptTerms = () => import('./accept-terms');
export const checkUserLogin = () => import('./check-user-login');
export const getConsent = () => import('./get-consent');
export const getPromotions = () => import('./get-promotions');
export const getStoreLocation = () => import('./get-store-location');
export const grantUserFirstLogin = () => import('./grant-user-first-login');
export const getOptimization = () => import('./get-optimization');
export const getCategoryProducts = () => import('./get-category-products');
